import { City, Country, State } from 'country-state-city';
import Cookies from 'js-cookie';
import { get, isEmpty } from "lodash";
import { convertLocalNumber, getPhoneCountryCode } from "../components/CustomPhoneInput";
import { buId, tenantId } from "../config/localStorage";
import { adminType } from "../constant/constant";
import { getActiveSnop, getConfiguration } from "../utils/ApiHandler";
import {
    MIN_LENGTH,
    URLSAFECHARS,
    USER_KEYS,
    carbonAnalyticsMenu,
    dataMappingMenu,
    demandMenu,
    entitiesMenu,
    inventoryMenu,
    productMenu,
    productionMenu,
    rawMaterialMenu,
    riskDtrmMenu,
    sideBarMenu,
    snopMenu,
    supply_configMenu,
    supply_operationMenu,
    supply_reportMenu,
    transactionMenu
} from "../utils/Constants";

export const getPath = (permissions, roles) => {
    const resultArray = [];
    sideBarMenu.forEach((item) => {
        if (
            (item.permission.some((r) => permissions.includes(r)) &&
                item.roles.length === 0) ||
            roles.includes(item.roles[0])
        ) {
            resultArray.push(item);
        }
    });

    snopMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });

    demandMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });

    productMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });

    entitiesMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });

    transactionMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    carbonAnalyticsMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    dataMappingMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    riskDtrmMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    inventoryMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    productionMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    rawMaterialMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    supply_reportMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    supply_operationMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    supply_configMenu.forEach((item) => {
        if (item.permission.some((r) => permissions.includes(r))) {
            resultArray.push(item);
        }
    });
    return resultArray[0];
};

const getUserMetaData = (_user) => {

    const metaData = {}, user = {};
    for (let key in _user) {
        if (USER_KEYS.includes(key))
            user[key] = _user[key];
        else
            metaData[key] = _user[key];
    }

    return { metaData, user };
}

export const setUserCookies = (_user) => {
    //delete user?.access;
    const { metaData, user } = getUserMetaData(_user);
    // delete user?.refresh;
    // delete user?.business_units;
    const blob = new Blob([JSON.stringify(user)]);
    console.log(blob.size);
    Cookies.set("user", JSON.stringify(user), { expires: 1 });
    localStorage.setItem("user", JSON.stringify(metaData));
}

export const processLogin = async (res, setLoader, selected_business_unit) => {
    if (res?.status === 200) {
        const users = res.data.data;
        const user = setUserLocalStorage(users);
        const path = !isEmpty(res) && getPath(user.permissions, user.roles);

        if (user.tenant_id && selected_business_unit?.business_unit_id) {
            const configurationResult = await getConfiguration(
                user.access,
                user.tenant_id,
                selected_business_unit?.business_unit_id
            );
            if (
                configurationResult?.status === 200
            ) {
                await getActiveSnop(
                    user.access,
                    user.tenant_id,
                    selected_business_unit?.business_unit_id
                );
                setUserCookies(user);
                window.location.replace(path.path);
            }
        } else {
            setUserCookies(user);
            window.location.replace(path.path);
        }
    }
    setLoader(false);
};

export const generateUrlSafeString = () => {
    let result = "";
    const charsLength = URLSAFECHARS.length;
    for (let i = 0; i < MIN_LENGTH; i++) {
        const array = new Uint32Array(1);
        result += URLSAFECHARS.charAt(Math.floor(crypto.getRandomValues(array) % charsLength));
    }
    return result;
};

function arrayBufferToBase64Url(buffer) {
    const byteArray = new Uint8Array(buffer);
    const base64 = btoa(String.fromCharCode.apply(null, byteArray));
    return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/={0,100}$/, "");
}

// Function to compute SHA-256 hash and encode it as Base64URL
export async function sha256Base64UrlEncoded(data) {
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(data);

    const hashBuffer = await crypto.subtle.digest("SHA-256", encodedData);
    const base64UrlEncoded = arrayBufferToBase64Url(hashBuffer);

    return base64UrlEncoded;
}

export const isNotificationEnabled = () => {
    if (buId && tenantId) return true;
    return false;
}

export const createBuOptions = (business_units) => {
    return business_units.map((bu) => {
        return {
            value: bu.id,
            label: bu.name,
            is_default: bu.is_default,
            teamId: bu?.team?.id
        };
    });
};

export const getLocalStorage = (key) => {
    return JSON.parse(localStorage.getItem(key));
}

export const isAdminUser = () => {
    const user = JSON.parse(localStorage.getItem("user") || null);
    const roles = get(user, "roles", []);
    for (let i = 0; i < roles.length; i++) {
        let role = roles[i];
        if (role in adminType) return true;
    }
    return false;
}

//Country state ciy dropdown Options
export const updatedStates = (countryId) =>
    State
        .getStatesOfCountry(countryId)
        .map((state) => ({ label: state.name, value: state.isoCode, ...state }));

export const updatedCities = (countryId, stateId) =>
    City
        .getCitiesOfState(countryId, stateId)
        .map((city) => ({ label: city.name, value: city.isoCode, ...city }));

export const updatedCountries = Country.getAllCountries().map((country) => ({
    label: country.name,
    value: country.isoCode,
    ...country,
}));

export const findCountry = (countryName) => {
    return Country.getAllCountries().find((country) => country.name === countryName);
}

export const findState = (countryId, stateName) => {
    const states = State.getStatesOfCountry(countryId);
    return states.find((state) => state.name === stateName);
}

export const createPayloadUserEdit = (formData, userId) => {
    const data = formData?.data || {};
    let localNumber = convertLocalNumber(formData.mobile);
    let countryCode = getPhoneCountryCode(formData.mobile);
    const bu = JSON.parse(localStorage.getItem("selected_business_unit") || "");
    const buId = bu?.business_unit_id;
    const businessUnits = data?.business_units?.map((item) => {
        const bu = {
            business_unit: item?.id,
            is_default: item?.id === buId ? true : false,
            team: item?.team?.id,
            roles: item?.roles.map((rl) => rl?.role?.id),
        };
        return bu;
    });

    const payload = {
        id: userId,
        organization: data?.organization?.id,
        business_unit: data?.business_unit?.id,
        team: data?.team?.id,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        mobile: localNumber,
        mobile_country_code: countryCode,
        address_line1: formData.address_line1,
        address_line2: formData.address_line2,
        country: formData.country?.label,
        state: formData.state?.value !== "" ? formData.state?.label : "",
        city: formData.city?.value !== "" ? formData.city?.label : "",
        pincode: formData.pincode,
        // roles: data?.roles?.map((role) => role.id) || [],
        business_units: businessUnits,
    };
    return payload;
};

export const createUserData = ({ data }) => {
    const country = findCountry(data?.country);
    const state = findState(country?.isoCode, data?.state);
    let roles = [];
    let buName = "";
    let teamName = "";
    const bu = JSON.parse(localStorage.getItem(
        "selected_business_unit",
    ) || "")
    const buId = bu?.business_unit_id;
    const businessUnits = data?.business_units;
    const businessUnitsLn = businessUnits?.length;
    for (let i = 0; i < businessUnitsLn; i++) {
        const buItem = businessUnits[i];
        if (buItem?.id !== buId) continue;
        buName = buItem?.name;
        teamName = buItem?.team?.name;
        const rl = buItem?.roles;
        const rlLn = rl?.length;
        for (let i = 0; i < rlLn; i++) {
            const rlItem = rl[i];
            const name = rlItem?.role?.name;
            roles.push(name);
        }
    }
    let obj = {
        organization: data?.organization?.name || "",
        first_name: data?.first_name || "",
        last_name: data?.last_name || "",
        email: data?.email || "",
        mobile_country_code: data?.mobile_country_code || "",
        mobile: data?.mobile_country_code + data?.mobile || "",
        team: teamName || "",
        business_unit: buName || "",
        address_line1: data?.address_line1 || "",
        address_line2: data?.address_line2 || "",
        country: {
            label: country?.name || data?.country || "Country",
            value: country?.isoCode || data?.country || "",
        },
        state: {
            label: state?.name || data?.state || "State",
            value: state?.isoCode || data?.state || "",
        },
        city: {
            label: data?.city || "City",
            value: data?.city || "",

        },
        pincode: data?.pincode || "",
        roles: roles?.join(", ") || "",
        data
    }
    return obj;
}

export const userObj = (user, id) => {
    let permissions = [];
    let roles = [];
    let businessUnit = user?.business_units;
    let businessUnitLn = businessUnit?.length;
    if (businessUnitLn === 0) return user;
    for (let i = 0; i < businessUnitLn; i++) {
        let buItem = businessUnit[i];
        if (id && buItem?.id !== id) continue;
        if (!id && !buItem?.is_default) continue;
        roles = buItem?.roles;
        permissions = buItem?.permissions;
        // let roleLn = role?.length;
        // for (let i = 0; i < roleLn; i++) {
        //     let roleItem = role[i];
        //     let item = roleItem?.role;
        //     roles.push(item?.name);
        //     permissions.push(...item?.permissions);
        // }
    }
    let newObj = {
        ...user,
        permissions,
        roles,
    };
    return newObj;
};

export const setUserLocalStorage = (user, id) => {
    let isAdmin = false;
    let roles = user?.roles;
    const rolesLn = roles?.length;
    for (let i = 0; i < rolesLn; i++) {
        let role = roles[i];
        if (role in adminType) {
            isAdmin = true;
            break;
        }
    }
    const newUser = userObj(user, id);
    if (id) {
        return newUser;
    } else {
        let userObj = isAdmin ? user : newUser;
        return userObj;
    }
};

export const matchPathName = (menu, path) => {
    if (!menu) return false;
    return (
        menu?.includes(path.slice(0, path.lastIndexOf("/")) + "/:id") ||
        menu?.includes(path.slice(0, path.lastIndexOf("/")) + "/:planId") ||
        menu?.includes(path.slice(0, path.lastIndexOf("/")) + "/:type") ||
        menu?.includes(path.slice(0, path.lastIndexOf("/")) + "/:planId/add-sto") ||
        menu?.includes(path)
    );
}

export const findDefaultBu = (buArr) => {
    const bu = buArr.find((bu) => bu.is_default);
    return {
        business_unit_id: bu?.id,
        business_unit_name: bu?.name,
    };
};

export function truncateString(input, num) {
    if (input.length > num) {
        return input.substring(0, num) + "...";
    }
    return input;
}